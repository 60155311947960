import apiAccount from '@/api/modules/api-account'

import Cookies from 'vue-cookies/vue-cookies'
export default {
  state: {
    token: null,
    selectedAccount: {
      accountId: null,
      accountRoles: null,
      accountSnsId: null,
      accountSnsList: [],
      createdAt: null,
      email: null,
      englishName: null,
      expired: null,
      koreanName: null,
      password: null,
      phone: '',
      providerType: null,
      accessToken: null,
      refreshToken: null,
      role: null,
      snsAccount: null,
      status: null,
      username: null,
      profileImageUrl: '',
      profileVideoUrl: '',
      major: '' ,
      career: '' ,
      personality: '' ,
      country: '' ,
      intro: '',
      zoomId: '',
      skypeId: ''
    },
    isAuthPhone: false,
  },
  getters: {
    selectedAccount: state => state.selectedAccount,
    selectedAccountId: state => state.selectedAccount.accountId,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      if (token){
        Cookies.set('accessToken',token)
      }else{
        Cookies.remove('accessToken')
        Cookies.remove('refreshToken')
      }
      state.token = token
    }
  },
  actions: {
    fetchSelectedUser ({state, commit}) {
      if (!state.selectedAccount.accountId) {
        return apiAccount.getUser()
          .then(res => {
            commit('SET_USER', res)
            return res
          })
          .then(account => {
            apiAccount.getAccountInfo(account.accountId)
              .then(accountInfo => {
                if (accountInfo.accountId) {
                  commit('SET_ACCOUNT_INFO', accountInfo)
                  return accountInfo
                }
              })
          })
      }
    }
  }
}
