<template>
  <v-main >

    <a class="btn btn-social mx-2 floating-button">
      <img src="@/assets/icon/kakaotalk_icon.svg" width="40"
           @click="onButtonClick">
    </a>
    <router-view/>

  </v-main>
</template>

<script>
export default {
  name: 'App',
  mounted() { },
  methods:{
    onButtonClick() {
      this.$openWindow("https://pf.kakao.com/_gjKqxb/chat", 'KakaoTalk')
      // Perform the desired action on button click
    },
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/main.scss";

.row {
  margin: -6px
}

.floating-button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 1000;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
}
</style>
